import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Link, Typography } from '@mui/material';
import classNames from 'classnames';
import { FC, useContext } from 'react';

import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { handleContactPhoneNumber } from '../../../util/commons';
import { useStyles } from './styles';

type Props = {
  withPaddingBottom?: boolean;
};

const HelpSection: FC<Props> = ({ withPaddingBottom }) => {
  const { portalConfigParams } = useContext(BusinessUnitParamsContext);

  const { contact_phone, contact_whatsapp } = portalConfigParams!;

  const classes = useStyles();
  return (
    <Box
      className={classNames(classes.containerHelp, withPaddingBottom && classes.withPaddingBottom)}
    >
      <Box display="flex" alignItems="center" mb={1}>
        <Typography className={classes.description}>{'¿Necesitas ayuda?'}</Typography>
      </Box>

      {/* Enlace de llamada */}
      <Box display="flex" alignItems="center" mb={1}>
        <Link underline="none" className={classes.link} color="inherit">
          <LocalPhoneIcon
            fontSize="small"
            style={{ verticalAlign: 'middle', marginRight: '10px' }}
          />
          {`Llámanos: ${handleContactPhoneNumber(contact_phone)}`}
        </Link>
      </Box>

      {/* WhatsApp */}
      {contact_whatsapp && (
        <Box display="flex" alignItems="center">
          <Link underline="none" className={classes.link} color="inherit">
            <WhatsAppIcon
              fontSize="small"
              style={{ verticalAlign: 'middle', marginRight: '10px' }}
            />
            {`WhatsApp: ${handleContactPhoneNumber(contact_whatsapp)}`}
          </Link>
        </Box>
      )}
    </Box>
  );
};

HelpSection.defaultProps = {
  withPaddingBottom: true,
};

export default HelpSection;
