import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: '#000',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
  },
  containerTitle: {
    display: 'grid',
    gap: '20px',
    width: '380px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  containerForm: {
    width: '380px',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
  },
  fakeStyle: {
    position: 'fixed',
    width: '0px',
    height: '0px',
    top: '-1000px',
    left: '-1000px',
    background: 'white',
    border: 'none',
  },
  subTitlenewLogin: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '12px !important',
    color: 'rgba(0, 0, 0, 0.60) !important',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    marginTop: '10px !important',
  },
  subTitleContainer: {
    margin: '16px 0px 32px 0px !important',
    display: 'flex !important',
    width: '380px',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      marginTop: '16px !important',
    },
  },
  divider: {
    margin: '10px 0px',
  },
  containerCreateUser: {
    display: 'grid',
    gap: '20px',
    marginTop: 20,
  },
  title: {
    color: '#000000DE',
    fontFamily: 'Roboto !important',
    fontSize: '22px !important',
    fontStyle: 'normal',
    fontWeight: '600  !important',
    lineHeight: '133.4%',
  },
  icon: {
    color: '#000000DE',
    cursor: 'pointer',
    border: '1px solid #000000DE',
    borderRadius: '100%',
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'red !important',
  },
}));
