import { useContext } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import Container from '../../../components/Containers/Container';
import ContainerPage from '../../../components/LayoutAuth/ContainerPage';
import { GOOGLE_CAPTCHA_API_KEY } from '../../../constants/constants';
import BusinessUnitParamsContext from '../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import LoginForm from './LoginForm';
import NewLoginForm from './NewLoginForm/index';

const Login = () => {
  const { portalConfigParams } = useContext(BusinessUnitParamsContext);
  const { newLogin } = portalConfigParams!;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_API_KEY}>
      <Container>
        <ContainerPage>{newLogin ? <NewLoginForm /> : <LoginForm />}</ContainerPage>
      </Container>
    </GoogleReCaptchaProvider>
  );
};

export default Login;
