import { CategoriesModel } from './CategoriesModel';

export type PortalConfigParamsModelType = {
  contact_phone: string;
  contact_whatsapp: string;
  businessUnitUUID: string;
  cover_description: string;
  cover_image_url: string;
  categories: Array<CategoriesModel>;
  newLogin: boolean;
};

export class PortalConfigParamsModel {
  contact_phone: string;
  contact_whatsapp: string;
  businessUnitUUID: string;
  cover_description: string;
  cover_image_url: string;
  categories: Array<CategoriesModel>;
  newLogin: boolean;
  constructor(
    contact_phone: string,
    contact_whatsapp: string,
    businessUnitUUID: string,
    cover_description: string,
    cover_image_url: string,
    categories: Array<CategoriesModel>,
    newLogin: boolean
  ) {
    this.contact_phone = contact_phone;
    this.contact_whatsapp = contact_whatsapp;
    this.businessUnitUUID = businessUnitUUID;
    this.cover_description = cover_description;
    this.cover_image_url = cover_image_url;
    this.categories = categories;
    this.newLogin = newLogin;
  }
}
