import { alpha, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  description: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    color: '#000',
    letterSpacing: '0.15px !important',
    lineHeight: '143% !important',
    padding: '1px 5px 1px 5px',
  },
  link: {
    fontFamily: 'Roboto !important',
    fontWeight: '400 !important',
    fontSize: '14px !important',
    lineHeight: '143%',
    cursor: 'pointer',
    color: '#000',
    padding: '1px 5px 1px 5px',
    transition: 'background-color 0.3s ease', // Transición suave para el fondo
    '&:hover': {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.08)} !important`, // Fondo translúcido
    },
  },
  containerHelp: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginTop: '10px',
    marginLefr: '-5px',
  },
  withPaddingBottom: {
    paddingBottom: 40,
  },
}));
