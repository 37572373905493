import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    maxHeight: '70px',
    maxWidth: '277.95px',
    marginBottom: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
  },
  logoNewLogin: {
    maxHeight: '72px',
    maxWidth: '141.37px',
    marginBottom: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0px 24px 0px',
      maxWidth: '117.8px',
      maxHeight: '60px',
    },
  },
}));
