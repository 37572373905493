import { Box, Button, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import servicesErrorImage from '../../assets/images/error404.svg';
import BusinessUnitParamsContext from '../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { useStyles as useStylesLogin } from '../../styles/global/stylesLogin';
import { handleContactPhoneNumber } from '../../util/commons';
import { useStyles } from './styles';

const ServicesErrorPage: FC = () => {
  const { portalConfigParams } = useContext(BusinessUnitParamsContext);
  const { contact_phone, contact_whatsapp } = portalConfigParams!;
  const classes = useStyles();
  const classesLogin = useStylesLogin();

  const history = useHistory();

  const navigateToHome = () => {
    history.push('/');
  };

  return (
    <Box className={classes.error}>
      <div className={classes.container}>
        <div className={classes.imageContainer}>
          <img className={classes.imageError} src={servicesErrorImage} alt="Error Image" />
        </div>
        <div className={classes.textContainer}>
          <Typography variant="h5" className={classes.title}>
            Lo sentimos
          </Typography>
          <br />
          <Typography color="textSecondary" className={classes.subtitle}>
            En este momento no podemos procesar tu solicitud por favor{'\n'}
            comunícate llamando al{' '}
            <span className={classes.number}>{handleContactPhoneNumber(contact_phone)}</span>
            {!contact_whatsapp && '.'}
            {contact_whatsapp && (
              <>
                {' '}
                o escríbenos por {'\n'}WhatsApp al{' '}
                <span className={classes.number}>
                  {handleContactPhoneNumber(contact_whatsapp)}.
                </span>
              </>
            )}
          </Typography>
          <br />
          <Button
            onClick={navigateToHome}
            style={{
              textTransform: 'none',
            }}
            className={classesLogin.button}
            classes={{ root: classes.buttonSubmit }}
            fullWidth
          >
            Volver al inicio
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default ServicesErrorPage;
